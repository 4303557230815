import Link from 'next/link';
import React from 'react';
import ResponsivePageLayout from '../components/layouts/ResponsivePageLayout';
import { LegalInfoOption, NextPageWithLayout, UserRequirement } from '../types';
const _404: NextPageWithLayout = () => {
  return (
    <>
      <div className="flex min-h-full flex-col items-center justify-center">
        <p className="text-8xl dark:text-white">404</p>
        <p className="mt-2 text-center text-3xl dark:text-white">This page doesn’t exist</p>

        <Link href="/discover" className="mt-10 font-sans text-plum-1 dark:text-dragonfruit-1">
          Discover new shows
        </Link>
      </div>
    </>
  );
};

_404.getLayout = (page) => {
  return (
    <ResponsivePageLayout
      title="Page Not Found"
      showLegalFooter={LegalInfoOption.Never}
      mainMargin="none"
      verticalAlignment="center"
    >
      {page}
    </ResponsivePageLayout>
  );
};

_404.userRequirement = UserRequirement.None;

export default _404;
